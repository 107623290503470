$(".newsPost__hover").hover(
    function () {
        $(this).find(".newsPost__thumbnail img").addClass("hover");
    },
    function () {
        $(this).find(".newsPost__thumbnail img").removeClass("hover");
    }
);

$(".lineup_categories").hover(
    function () {
        $(this).find(".img").addClass("hover");
    },
    function () {
        $(this).find(".img").removeClass("hover");
    }
);

$("#home__technology a").hover(
    function () {
        $(".home__technology__left__img img").addClass("hover");
    },
    function () {
        $(".home__technology__left__img img").removeClass("hover");
    }
);

$(".techniquePost__post__item").hover(
    function () {
        $(this).find(".techniquePost__post__thumbnail img").addClass("hover");
    },
    function () {
        $(this)
            .find(".techniquePost__post__thumbnail img")
            .removeClass("hover");
    }
);

$(".home__lineup__post__item").hover(
    function () {
        $(this).find(".home__lineup__post__thumbnail img").addClass("hover");
    },
    function () {
        $(this).find(".home__lineup__post__thumbnail img").removeClass("hover");
    }
);

$(".footerBanner__order").hover(
    function () {
        $(".footerBanner__order img").addClass("hover");
    },
    function () {
        $(".footerBanner__order img").removeClass("hover");
    }
);

$(".footerBanner__showroom a").hover(
    function () {
        $(".footerBanner__showroom__right img").addClass("hover");
    },
    function () {
        $(".footerBanner__showroom__right img").removeClass("hover");
    }
);

$("#footerBannerCompany a").hover(
    function () {
        $(this).find(".footerBanner__company__wrap img").addClass("hover");
    },
    function () {
        $(this).find(".footerBanner__company__wrap img").removeClass("hover");
    }
);
